import styled from 'styled-components';

const ScrollableFilterBarStyle = styled.div`
	--filterItemPadding: var(--sp3x) var(--sp7x);
	--filterItemMarginRight: var(--sp5x);

	display: flex;
	width: 100%;

	.container-wrapper {
		overflow: hidden;
	}

	.filters-wrap {
		display: flex;
		gap: var(--filterItemMarginRight);
		width: fit-content;

		.filter-item {
			cursor: pointer;

			&.active {
				pointer-events: none;

				.name {
					background-color: var(--black1000) !important;
					color: var(--white) !important;
					transition: unset;
				}
			}

			.name {
				position: relative;
				overflow: hidden;
				display: inline-block;
				padding: var(--filterItemPadding);
				background-color: var(--white);
				color: var(--black1000);
				border-radius: var(--sp1x);
				white-space: nowrap;
				pointer-events: none;
				transition: 0.3s ease-out;

				.content-text {
					position: relative;
					z-index: 1;
					transition: 0.3s ease-out;
				}

				.layer {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transform: rotate(180deg);
					background: linear-gradient(var(--black900) 0 0) var(--backgroundPosition, 0) / var(--backgroundPosition, 0) no-repeat;
					transition: 0.4s, background-position 0s;
				}
			}

			@media (hover: hover) {
				&:hover {
					.name {
						.content-text {
							color: var(--white);
						}

						.layer {
							--backgroundPosition: 100%;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--filterItemPadding: var(--sp2x) var(--sp4x);
		--filterItemMarginRight: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--filterItemPadding: var(--sp2x) var(--sp4x);
		--filterItemMarginRight: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--filterItemPadding: var(--sp2x) var(--sp4x);
		--filterItemMarginRight: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--filterItemPadding: var(--sp2x) var(--sp3x);
		--filterItemMarginRight: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--filterItemPadding: var(--sp2x) var(--sp3x);
		--filterItemMarginRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--filterItemPadding: var(--sp2x) var(--sp3x);
		--filterItemMarginRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--filterItemPadding: var(--sp2x) var(--sp2-5x);
		--filterItemMarginRight: var(--sp1-5x);
	}
`;

export default ScrollableFilterBarStyle;
