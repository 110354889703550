import styled from 'styled-components';

const FacilitiesSectionStyle = styled.div`
	--surroundingsFilterMargin: var(--sp12x);
	--surroundingsNextBtnMarginLeft: var(--sp6x);

	margin-bottom: var(--sectionDistance);

	.surroundings-filter {
		margin: var(--surroundingsFilterMargin) 0;
	}

	.image-col {
		overflow: hidden;
		position: relative;
		height: 100%;

		.image-wrap {
			overflow: hidden;
			position: relative;
			transform: scale(1.2);
			clip-path: inset(0% 0% 100% 0%);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--surroundingsFilterMargin: var(--sp10x);
		--surroundingsNextBtnMarginLeft: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--surroundingsFilterMargin: var(--sp8x);
		--surroundingsNextBtnMarginLeft: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--surroundingsFilterMargin: var(--sp6x);
		--surroundingsNextBtnMarginLeft: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--surroundingsFilterMargin: var(--sp6x);
		--surroundingsNextBtnMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--surroundingsFilterMargin: var(--sp5x);
		--surroundingsNextBtnMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--surroundingsFilterMargin: var(--sp5x);
		--surroundingsNextBtnMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--surroundingsFilterMargin: var(--sp3x);
		--surroundingsNextBtnMarginLeft: var(--sp2x);
	}
`;

export default FacilitiesSectionStyle;
