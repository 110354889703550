//* Styles
import ItemsFacilitiesStyle from './style';

//* Component
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import Container from '@/components/common/Container';

const ItemsFacilities = ({ image, description, imageClassName, className }) => {
	return (
		<ItemsFacilitiesStyle className={imageClassName}>
			<Image
				src={image.url}
				alt={image.alt}
			/>
			<div className='content-description-wrap'>
				<Container
					full
					row>
					<div className='col-5 col-dl-7 col-dxs-8 col-ts-12'>
						<div className='layer-wrapper'>
							<Text className={'h6 font-montserrat content-description '}>{description}</Text>
						</div>
					</div>
				</Container>
			</div>
		</ItemsFacilitiesStyle>
	);
};

export default ItemsFacilities;
