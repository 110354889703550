export default function numberSpecialSymbol({ symbol = ',', number, count = 3 }) {
	number = number.toString();
	if (number !== 'Infinity') {
		let numberLength = number.length;
		let returnNumber = '';

		if (numberLength > count) {
			returnNumber = number.substring(0, numberLength % count || count);

			for (let i = numberLength % count || count; i < numberLength; i += count) {
				returnNumber += symbol + number.substring(i, i + count);
			}
		} else {
			returnNumber = number;
		}

		return returnNumber;
	} else {
		return number;
	}
}

// numberSpecialSymbol({ symbol: '.', number: defaultValue })
