import styled from 'styled-components';

const PageStyle = styled.main`
	padding-top: var(--headerFixedHeight);

	&.home,
	&.building {
		padding-top: unset;
	}
	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
	}
	/* //! 1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
	}
	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}
	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
	}
	/* //! 414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
	}
`;

export default PageStyle;
