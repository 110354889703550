'use client';

import gsap from 'gsap';
import { ScrollTrigger, SplitText } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';

//* Component
import Resize from './Resize';

//! Trigger id count for test
let a = 0;

const ScrollActive = ({ animTitle, allRefs, reverse, duration, delay, stagger, once, typeWordLines, markerStart, markerEnd, marker, markerContainer, percentDuration, percent, percentFunction, children }) => {
	//! Plugins
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(SplitText, ScrollTrigger);

	//! Refs
	const refTrigger = useRef();
	const mySplitText = useRef();
	const lineText = useRef();

	//! Animation state
	const animationIsEndedRef = useRef(false);

	//! Router
	const pathname = usePathname();

	//! State Resize
	const [resize, setResize] = useState();

	//! Animation
	useEffect(() => {
		//! Animation resize but ended
		// let animationIsEnded = false;

		if (animTitle && resize && !animationIsEndedRef.current) {
			a++;
			const defaultConfig = {
				duration: 0.5,
				stagger: 0.2,
				delay: 0,
				once: true,
				marker: false,
			};

			refTrigger.current = ScrollTrigger.create({
				trigger: markerContainer ? markerContainer.current : allRefs.current,
				start: `${markerStart || 'center'} 90%`,
				end: `${markerEnd || 'center'} top`,
				markers: marker || defaultConfig.marker,
				once: once == false ? once : defaultConfig.once,
				id: `marker-${a}`,

				onEnter: () => {
					mySplitText.current = new SplitText(allRefs.current, { type: `${typeWordLines} lines`, linesClass: 'lineChild' });
					lineText.current = new SplitText(allRefs.current, { type: `${typeWordLines} lines`, linesClass: 'lineParent' });

					//! Get Percent
					let getPercent = Math.round(mySplitText.current[typeWordLines].length * ((duration || defaultConfig.duration) + (stagger ? stagger : defaultConfig.stagger)));

					if (percentDuration) {
						getPercent = (percentDuration * getPercent) / 100;
					} else {
						getPercent = (75 * getPercent) / 100;
					}

					gsap.to(allRefs.current, {
						opacity: 1,
						duration: 0,
					}).then(() => {
						if (percent) {
							percentFunction(getPercent);
						}

						gsap.set(typeWordLines == 'lines' ? mySplitText.current[typeWordLines] : lineText.current[typeWordLines], { y: reverse ? -100 : 100 });
						gsap.set('.lineParent', { overflow: 'hidden' });
						gsap.to(typeWordLines == 'lines' ? mySplitText.current[typeWordLines] : lineText.current[typeWordLines], {
							y: 0,
							ease: 'power2.out',
							duration: duration || defaultConfig.duration,
							stagger: stagger || defaultConfig.stagger,
							delay: delay || defaultConfig.delay,
						}).then(() => {
							//! revert function to standart css after animation
							lineText.current.revert();
							mySplitText.current.revert();
							if (allRefs.current) {
								gsap.to(allRefs.current, { opacity: 1, duration: 0 });
							}
						});
						animationIsEndedRef.current = true;
					});
				},
			});
		}

		return () => {
			if (!animationIsEndedRef.current) {
				if (refTrigger.current) {
					refTrigger.current.kill();
				}
				if (mySplitText.current) {
					mySplitText.current.revert();
					lineText.current.revert();
				}
			}
		};
	}, [resize, pathname]);

	return <Resize setResize={setResize}>{children}</Resize>;
};

export default ScrollActive;
