import { useState, useMemo, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

//* Style
import FacilitiesSectionStyle from './style';

//* Libs
import { getInfo } from '@/libs/get-data';

//* HOO'C
import { withDataContext, withLanguageContext } from '@/context';

//* Components
import ItemsFacilities from './Items';
import Text from '@/components/common/Text';
import Container from '@/components/common/Container';
import ScrollActive from '@/components/common/ScrollActive';
import ScrollableFilterBar from '@/components/common/ScrollableFilterBar';

const FacilitiesSection = ({ globalData, selectedLang }) => {
	//! Refs
	const sectionRef = useRef();
	const titleRef = useRef();
	const imageWrapRef = useRef();
	const scrollTrigger = useRef();
	const intervalRef = useRef();
	const nextItem = useRef();

	// //! States
	const [data, setData] = useState([]);
	const [activeFilter, setActiveFilter] = useState();

	//! Filtered Id
	const filterArrayID = useRef();

	//! Data
	useEffect(() => {
		getInfo({ slug: 'facilities-section', lang: selectedLang }).then((res) => {
			filterArrayID.current = res.map((item) => item.id);
			setData(res);
			setActiveFilter(res[0].id);
		});
	}, [selectedLang]);

	// //! Active filter data
	const activeFilterInfo = useMemo(() => {
		return data?.find((item) => item.id === activeFilter);
	}, [activeFilter, data]);

	//! Item
	const storeAllImages = useMemo(() => {
		return data?.map((item, index) => {
			const activeIndex = data?.findIndex((item) => item.id === activeFilterInfo.id);

			const prevClass = activeIndex > index ? 'prev-images' : '';
			const nextClass = activeIndex < index ? 'next-images' : '';

			return (
				<ItemsFacilities
					imageClassName={`image ${activeIndex === index ? 'active' : ''} ${prevClass} ${nextClass}`}
					index={index}
					key={index}
					{...item}
				/>
			);
		});
	}, [data, activeFilterInfo]);

	//! First scrolling
	useEffect(() => {
		scrollTrigger.current = ScrollTrigger.create({
			start: 'top bottom',
			end: 'top top',
			trigger: imageWrapRef.current,
			// markers: true,
			onEnter: () => {
				imageWrapRef.current && gsap.to('.image-wrap', { scale: 1, clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.easeOut', duration: 1 });
			},
		});

		return () => {
			if (scrollTrigger.current) scrollTrigger.current.kill();
		};
	});

	//! Autoplay
	useEffect(() => {
		if (activeFilter && filterArrayID.current) {
			const index = filterArrayID.current.indexOf(activeFilter);
			if (index !== -1) {
				nextItem.current = filterArrayID.current[(index + 1) % filterArrayID.current.length];
			}
		}

		if (intervalRef.current) {
			clearTimeout(intervalRef.current);
		}

		if (nextItem.current) {
			intervalRef.current = setTimeout(() => {
				setActiveFilter(nextItem.current);
			}, 5000);
		}
	}, [activeFilter]);

	return (
		<FacilitiesSectionStyle ref={sectionRef}>
			{data.length > 0 && (
				<>
					<Container>
						<div className='title-wrap'>
							<ScrollActive
								animTitle
								allRefs={titleRef}
								typeWordLines={`lines`}>
								<Text
									className={'h1 font-montserrat-medium title first-latter opacity-0'}
									ref={titleRef}>
									{globalData[selectedLang].facility_title}
								</Text>
							</ScrollActive>
						</div>
					</Container>

					<div className='wrapper-category'>
						<ScrollableFilterBar
							activeFilter={activeFilter}
							setActiveFilter={setActiveFilter}
							filters={data}
							className={'surroundings-filter'}
						/>

						<Container row>
							<div className='wrapper-item'>
								<div className='col-12 image-col'>
									<div
										className='image-wrap'
										ref={imageWrapRef}>
										{storeAllImages}
									</div>
								</div>
							</div>
						</Container>
					</div>
				</>
			)}
		</FacilitiesSectionStyle>
	);
};

export default withLanguageContext(withDataContext(FacilitiesSection, ['globalData']), ['selectedLang']);
