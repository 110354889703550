'use client';

import { useEffect } from 'react';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import PageStyle from './style';

const Page = (props) => {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';

		window.onbeforeunload = function () {
			window.history.scrollRestoration = 'manual';
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	return <PageStyle className={props.className || ''}>{!props.preloader ? props.children : null}</PageStyle>;
};

Page.displayName = 'Page';

export default withUIContext(Page, ['preloader']);
