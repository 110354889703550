import styled from 'styled-components';

const ItemsFacilitiesStyle = styled.div`
	--distanceDescriptionLB: var(--sp7x);
	--descriptionTB: var(--sp5x);
	--descriptionLR: var(--sp4x);
	--borderRadius: var(--sp5x);
	--imageSize: 45.134%;

	.content-description-wrap {
		position: absolute;
		bottom: var(--distanceDescriptionLB);
		left: var(--distanceDescriptionLB);
		color: var(--white);
		width: calc(100% - var(--distanceDescriptionLB));

		.layer-wrapper {
			position: relative;
			width: fit-content;

			&::before {
				content: '';
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				opacity: 0.3;
				background-color: var(--black1000);
				z-index: 1;
				border-radius: var(--borderRadius);
				overflow: hidden;
			}
		}

		.content-description {
			position: relative;
			left: 0;
			z-index: 30;
			padding: var(--descriptionTB) var(--descriptionLR);
		}
	}

	&.image {
		.image-cont {
			--proportion: var(--imageSize);
		}

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		transition: 0.8s ease-out;
		pointer-events: none;

		&.next-images {
			clip-path: inset(0% 0% 0% 100%);
			overflow: hidden;
			z-index: 2;
			transition: 0.8s ease-out;
		}

		&.prev-images {
			clip-path: inset(0% 100% 0% 0%);
			overflow: hidden;
			z-index: 2;
			transition: 0.8s ease-out;
		}

		&.active {
			position: relative;
			pointer-events: all;
			clip-path: inset(0 0 0 0);
			transition: 0.8s ease-out;
		}
	}
	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--distanceDescriptionLB: var(--sp5x);
		--descriptionTB: var(--sp5x);
		--descriptionLR: var(--sp4x);
		--borderRadius: var(--sp3x);
		--imageSize: 49.7%;
	}
	/* //! 1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--distanceDescriptionLB: var(--sp4x);
		--descriptionTB: var(--sp5x);
		--descriptionLR: var(--sp4x);
		--borderRadius: var(--sp3x);
		--imageSize: 41%;
	}
	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--distanceDescriptionLB: var(--sp4x);
		--descriptionTB: var(--sp4x);
		--descriptionLR: var(--sp3x);
		--borderRadius: var(--sp3x);
		--imageSize: 41.3%;
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--distanceDescriptionLB: var(--sp3x);
		--descriptionTB: var(--sp4x);
		--descriptionLR: var(--sp3x);
		--borderRadius: var(--sp3x);
		--imageSize: 41.3%;
	}
	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--distanceDescriptionLB: var(--sp3x);
		--descriptionTB: var(--sp3x);
		--descriptionLR: var(--sp2x);
		--borderRadius: var(--sp3x);
		--imageSize: 46.2%;
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--distanceDescriptionLB: var(--sp3x);
		--descriptionTB: var(--sp3x);
		--descriptionLR: var(--sp2x);
		--borderRadius: var(--sp3x);
		--imageSize: 50.4%;

		.content-description-wrap {
			position: relative;
			top: 0;
			left: 0;
			color: var(--black1000);
			width: 100%;

			.layer-wrapper {
				&::before {
					background-color: transparent;
				}
			}

			.content-description {
				margin-top: var(--sp5x);
				padding: 0;
			}
		}
	}
	/* //! 414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--imageSize: 62.7%;

		.content-description-wrap {
			position: relative;
			top: 0;
			left: 0;
			color: var(--black1000);
			width: 100%;

			.layer-wrapper {
				&::before {
					background-color: transparent;
				}
			}

			.content-description {
				margin-top: var(--sp3x);
				padding: 0;
			}
		}
	}
`;
export default ItemsFacilitiesStyle;
